(function($) {
    "use strict";
	
	AOS.init({
		easing: 'ease-out-back',
		duration: 1000
	});
	
    $(document).ready(function() {
		

        fullscreen_section($(this));
        parallax_image();
        fix_height();
        mobile_nav($(this));
        one_page_scroll($(this));
        sticky_header($(this));
		
		$('.accordionTitle').click(function(ev){
			ev.preventDefault();
			var accContent = $(this).next();
			if($(this).hasClass('opened')){
				$(this).removeClass('opened');
				accContent.css({height:0});
			}else{
				$(this).addClass('opened');
				if($(this).parent().hasClass('clinicCard')){
					accContent.css({height:'100%'});
				}else{
					var accHeight = accContent.find('.accordionContentBox').height()+80;
					accContent.css({height:accHeight});
				}
			}
		});
		
		

		$('#search_it-form2 a').click(function(ev){
			ev.preventDefault();
			$('.menu #headerSearchBox').toggleClass('opened');
		});
		
		$('#search_it-form3 a').click(function(ev){
			ev.preventDefault();
			$('#header #mobileSearchBox').toggleClass('opened');
		});
		
		$('#mobilePhoneBoxToggle').click(function(ev){
			ev.preventDefault();
			$('#mobilePhoneBoxNumber').toggleClass('opened');
		});
		
		
		$('#mobilePhoneIcon, .phoneNavIcon').click(function(ev){
			ev.preventDefault();
			$('#mobilePhoneBox').toggleClass('activated');
			$('html').removeClass('naviOpened');
			$('.mobileSubNavOpened').removeClass('mobileSubNavOpened');
		});
		
		$('#mobilePhoneBoxClose').click(function(ev){
			ev.preventDefault();
			$('#mobilePhoneBox').toggleClass('activated');
		});

		$('.closeClinicAccordion').click(function(ev){
				ev.preventDefault();
				$(this).parent().css({height:0}).prev().removeClass('opened');
			});
		
		//placeholders for images
		var pageWidthForBB = $(window).width();
		if($('.backgroundOptions').length){
			
			$('.backgroundOptions').each(function(){
				if(pageWidthForBB<992){
					var backgroundDataMobile = $(this).attr('data-style-mobile');
					if(backgroundDataMobile=='' || backgroundDataMobile.length<1){
						backgroundDataMobile = $(this).attr('data-style');
					}
					$(this).attr('style',backgroundDataMobile);
				}else{
					var backgroundData = $(this).attr('data-style');
					$(this).attr('style',backgroundData);
				}
			});
		}
		
		
		/*$('.formDataCheck').click(function(ev) {
			ev.preventDefault();
			var buttonForm = $(this).parent().next('button');
			if($(this).hasClass('checked')){
				$(this).removeClass('checked');
				buttonForm.prop('disabled', true);
			}else{
				$(this).addClass('checked');
				buttonForm.prop('disabled', false);
			}
			
		});*/
		
		$('.formDataCheckFirst').click(function(ev) {
			ev.preventDefault();
			var buttomForm = $(this).parent().next().next('button');
			var checkSecond = $(this).parent().next().find('.formDataCheckSecond');
			if($(this).hasClass('checked')){
				$(this).removeClass('checked');
			}else{
				$(this).addClass('checked');
			}
			if($(this).hasClass('checked') && checkSecond.hasClass('checked')){
				buttomForm.prop('disabled', false);
			}else{
				buttomForm.prop('disabled', true);
			}
		});
		
		$('.formDataCheckSecond').click(function(ev) {
			ev.preventDefault();
			var buttomForm = $(this).parent().next('button');
			var checkFirst = $(this).parent().prev().find('.formDataCheckFirst');
			if($(this).hasClass('checked')){
				$(this).removeClass('checked');
			}else{
				$(this).addClass('checked');
			}
			if($(this).hasClass('checked') && checkFirst.hasClass('checked')){
				buttomForm.prop('disabled', false);
			}else{
				buttomForm.prop('disabled', true);
			}
		});
		
		$(window).resize(function() {
			$('.accordionTitle').removeClass('opened');
			$('.accordionContent').css({height:0});
			if($('.backgroundOptions').length){
				var pageWidthTest = $(window).width();
				if(pageWidthForBB != pageWidthTest){
					if(pageWidthForBB<992 && pageWidthTest>991){
						$('.backgroundOptions').each(function(){
							var backgroundData = $(this).attr('data-style');
							$(this).attr('style',backgroundData);
						});
						pageWidthForBB = pageWidthTest;
					}else if(pageWidthForBB>991 && pageWidthTest<992){
						$('.backgroundOptions').each(function(){
							var backgroundDataMobile = $(this).attr('data-style-mobile');
							if(backgroundDataMobile!='' && backgroundDataMobile.length>0){
								$(this).attr('style',backgroundDataMobile);
							}
						});
						pageWidthForBB = pageWidthTest;
					}
				}
			}
		});
		
		
		//formreset
        $('input').not('#formContactLang, #formEasyLang, #formEasyLang2, #formCallBackLang, #formCallBackExtLang').val('');
		$('textarea').val('');

		//fullpage for home
		/*if($('body').attr('id')=='pageID-53'){
			var counted = false;
			$('#pageBox').fullpage({
				responsiveWidth: 767,
				responsiveHeight: 767,
				scrollBar: true,
				onLeave: function(index, nextIndex, direction){
					if(nextIndex!=1){
						$('#header').addClass('sticky');
						$('body').addClass('headerSticky');
					}else{
						$('#header').removeClass('sticky');
						$('body').removeClass('headerSticky');
					}
					if(nextIndex==3){
						setTimeout(function(){$('.img6box').addClass('popped')}, 500);
					}
					if(nextIndex==2){
						if ($('.priceWithInfo').length && !counted) {
							$('.price').each(function () {
								$(this).prop('Counter',0).animate({
									Counter: $(this).text()
								}, {
									duration: 5000,
									easing: 'swing',
									step: function (now) {
										$(this).text(Math.ceil(now));
									}
								});
							});
							counted = true;
						}
					}
				}
			});
			
			$('.sectionGMup').click(function(){
				$.fn.fullpage.moveSectionUp();
			});
			
			$('.sectionGMdown').click(function(){
				$.fn.fullpage.moveSectionDown();
			});
			
			var initURL = location.href;
			if(initURL.indexOf("#infobox")>-1){
				$.fn.fullpage.moveTo(4);
			}
			
			$('.rex-article-528 a').click(function(ev){
				ev.preventDefault();
				$.fn.fullpage.moveTo(4);
				$('#header').removeClass('menu-open');
				$('html').removeClass('naviOpened');
			});
		}*/
		
		//swipersliders
        if ($('.slider2col').length) {
            var slider2col = new Swiper('.slider2col', {
                spaceBetween: 0,
                loop: true,
				loopAdditionalSlides: 3,
				navigation: {
					nextEl: '.col2-button-next',
					prevEl: '.col2-button-prev',
				 },
            });

            /*slider2col.on('slideChangeEnd', function() {
                if (slider2col.activeIndex == 0) {
                    $('.col2-button-prev').addClass('button-disabled');
                } else {
                    $('.col2-button-prev').removeClass('button-disabled');
                }
                if (slider2col.activeIndex == $('.swiper-container.slider2col .swiper-slide').length - 1) {
                    $('.col2-button-next').addClass('button-disabled');
                } else {
                    $('.col2-button-next').removeClass('button-disabled');
                }
            });*/
        }
		
		if ($('#faqBeforeSlide').length) {
            var sliderBefore = new Swiper('#faqBeforeSlide', {
                spaceBetween: 0,
                loop: false,
				autoHeight: true,
				pagination: '#faqBeforeSlide .swiper-pagination'
            });

            sliderBefore.on('slideChangeEnd', function() {
                if (sliderBefore.activeIndex == 0) {
                    $('#before .before-button-prev, .prevFAQ').addClass('button-disabled');
					$('#before .before-button-next, .nextFAQ').removeClass('button-disabled');
                } else {
                    $('#before .before-button-prev, .prevFAQ').removeClass('button-disabled');
                    if (sliderBefore.activeIndex == $('.swiper-container#faqBeforeSlide .swiper-slide').length - 1) {
                        $('#before .before-button-next, .nextFAQ').addClass('button-disabled');
                    } else {
                        $('#before .before-button-next, .nextFAQ').removeClass('button-disabled');
                    }
                }
            });

            $('#before .before-button-prev, .prevFAQ').click(function(ev) {
				ev.preventDefault();
                sliderBefore.slidePrev();
            });

            $('#before .before-button-next, .nextFAQ').click(function(ev) {
				ev.preventDefault();
                sliderBefore.slideNext();
            });
        }
		
		if ($('#faqAfterSlide').length) {
			
			var sliderAfter = new Swiper('#faqAfterSlide', {
				spaceBetween: 0,
				loop: true,
				loopAdditionalSlides: 3,
				navigation: {
					nextEl: '#after .after-button-next',
					prevEl: '#after .after-button-prev',
				 },
				pagination: '#faqAfterSlide .swiper-pagination'
			});
			
			var firstClick = true;
			$('#afterSlider').click(function(ev){
				if(firstClick){
					setTimeout(function() {
                
					sliderAfter.update()
					firstClick = false;
					}, 420);
				}
			});
            

            sliderAfter.on('slideChangeEnd', function() {
                if (sliderAfter.activeIndex == 0) {
                    $('#after .after-button-prev').addClass('button-disabled');
					$('#after .after-button-next').removeClass('button-disabled');
                } else {
                    $('#after .after-button-prev').removeClass('button-disabled');
                    if (sliderAfter.activeIndex == $('.swiper-container#faqAfterSlide .swiper-slide').length - 1) {
                        $('#after .after-button-next').addClass('button-disabled');
                    } else {
                        $('#after .after-button-next').removeClass('button-disabled');
                    }
                }
            });

        }

        if ($('.sliderTabs').length) {
            new Swiper('.swiper-container.sliderTabs', {
                slidesPerView: 'auto',
                spaceBetween: 0,
                loop: true,
				loopAdditionalSlides: 3,
				navigation: {
					nextEl: '.tab-button-next',
					prevEl: '.tab-button-prev',
				 },
            });

            /*sliderTabs.on('slideChangeEnd', function() {
                if (sliderTabs.activeIndex == 0) {
                    $('.tab-button-prev').addClass('button-disabled');
                } else {
                    $('.tab-button-prev').removeClass('button-disabled');
                    var pageWidth = $(window).width();
                    var operationParameter = 4;
                    if (pageWidth < 1200) {
                        if (pageWidth < 992) {
                            if (pageWidth < 550) {
                                operationParameter = 1;
                            } else {
                                operationParameter = 2;
                            }
                        } else {
                            operationParameter = 3;
                        }
                    }
                    if (sliderTabs.activeIndex == $('.swiper-container.sliderTabs .swiper-slide').length - operationParameter) {
                        $('.tab-button-next').addClass('button-disabled');
                    } else {
                        $('.tab-button-next').removeClass('button-disabled');
                    }
                }
            });*/
        }
		
		if ($('.sliderTabs2').length) {
            new Swiper('.swiper-container.sliderTabs2', {
                slidesPerView: 'auto',
                spaceBetween: 0,
                loop: true,
				loopAdditionalSlides: 3,
				navigation: {
					nextEl: '.tab2-button-next',
					prevEl: '.tab2-button-prev',
				 },
            });
        }

        if ($('.sliderDocs.activeSlider').length) {
            new Swiper('.sliderDocs.activeSlider', {
                slidesPerView: 'auto',
                spaceBetween: 0,
                loop: true,
				loopAdditionalSlides: 3,
				navigation: {
                nextEl: '.docs-button-next',
                prevEl: '.docs-button-prev',
             },
            });
        }
        if ($('.sliderWithBullets').length) {
            var swiperBullets = new Swiper('.sliderWithBullets', {
                pagination: '.sliderWithBullets .swiper-pagination',
                paginationClickable: true,
                spaceBetween: 0,
                loop: true,
                loopAdditionalSlides: 1
            });
        }

		//treatments boxe with pop-up
        if ($('.treatmentsSection').length) {

            var treatmentsLoaded = false;

            $('.treatmentsPopUp').click(function() {
                var currentListId = $(this).attr('data-id');
                var currentContentId = $(this).attr('data-first-item-id');
                if (!treatmentsLoaded) {
                    var contentForPopUpLeft = $('#treatmentsListContent');
                    var contentForPopUpRight = $('#treatmentsInfoContent');
                    $('#pageInfoBox2colContentLeft').append(contentForPopUpLeft);
                    $('#pageInfoBox2colContentRight').append(contentForPopUpRight);
                    treatmentsLoaded = true;
                    $('.scrollBox').mCustomScrollbar();
                }
                $('.treatmentsContent.current, .treatmentsInfoItem.current').hide(0);
                $('.treatmentsContent[data-id="' + currentContentId + '"]').addClass('current').show(0);
                $('.treatmentsInfoItem[data-id="' + currentListId + '"]').addClass('current').show(0);
                $('#treatmentsListContent .treatmentsInfoItem.current span.current').removeClass('current');
                $('#treatmentsListContent .treatmentsInfoItem.current span:eq(0)').addClass('current');
                $('#pageInfoBackground').css({ left: 0 });
                $('#pageInfoBox').css({ left: '50%' });
                $('#pageInfoBackground').css({ opacity: 0.8 });
                $('#pageInfoBox').css({ opacity: 1 });
            });

            $('#pageInfoBackground, #pageInfoBoxClose').click(function(ev) {
                ev.preventDefault();
                $('#pageInfoBackground, #pageInfoBox').css({ opacity: 0 });
                setTimeout(function() {
                    $('#pageInfoBackground, #pageInfoBox').css({ left: -10000 });
                }, 420);
            });

            $('#treatmentsListContent .treatmentsInfoItem span').click(function() {
                var clickedItemId = $(this).attr('data-id');
                $('#treatmentsListContent .treatmentsInfoItem.current span.current').removeClass('current');
                $(this).addClass('current');
                $('#pageInfoBox2colContentRight .treatmentsContent.current').removeClass('current').hide(0);
                $('#pageInfoBox2colContentRight .treatmentsContent[data-id="' + clickedItemId + '"]').addClass('current').show(0);
            });

            $(window).resize(function() {
                $('#treatmentsCurrentList').css({ height: 0 }).remove();
            });
        };
		
		//6er box
        if ($('.box6info').length) {

            $('.box6info').click(function() {
                var infoContent = $(this).find('.dataForPopUp .textPopUp').clone();
                $('#pageInfoBox2colContent').empty().append(infoContent);
                //$('#pageInfoBox2colContent .textPopUp').mCustomScrollbar();
                $('#pageInfoBackground').css({ left: 0 });
                $('#pageInfoBox').css({ left: '50%' });
                $('#pageInfoBackground').css({ opacity: 0.8 });
                $('#pageInfoBox').css({ opacity: 1 });
            });

            $('#pageInfoBackground, #pageInfoBoxClose').click(function(ev) {
                ev.preventDefault();
                $('#pageInfoBackground, #pageInfoBox').css({ opacity: 0 });
                setTimeout(function() {
                    $('#pageInfoBackground, #pageInfoBox').css({ left: -10000 });
                }, 420);
            });
        };

        var timerText = null;


        $('.borderSection .buttonOpen, .no-border .buttonOpen').click(function(ev) {
            ev.preventDefault();
            var clickedParent = $(this).parent();
            var textHeight = clickedParent.prev().find('.text2partsContent').height();
            clickedParent.prev().css({ height: textHeight }).addClass('textAnimated');
            clickedParent.prev().find('.textPoints').hide(0);
            clickedParent.prev().find('.textToShow').show(0);
            textHeight = clickedParent.prev().find('.text2partsContent').height();
            clickedParent.prev().css({ height: textHeight });
            $(this).removeClass('active');
            clickedParent.find('.buttonClose').addClass('active');
            timerText = setTimeout(function() {
                clickedParent.prev().removeClass('textAnimated').css({ height: 'auto' });
            }, 300);
        });

        $('.borderSection .buttonClose, .no-border .buttonClose').click(function(ev) {
            ev.preventDefault();
            clearTimeout(timerText);
            timerText = null;
            var clickedParent = $(this).parent();
            var textHeight = clickedParent.prev().find('.text2partsContent').height();
            clickedParent.prev().css({ height: textHeight }).addClass('textAnimated');
            setTimeout(function() {
                textHeight = clickedParent.prev().find('.textIntro').height();
                clickedParent.prev().css({ height: textHeight });
            }, 10);
            timerText = setTimeout(function() {
                clickedParent.prev().find('.textPoints').show(0);
                clickedParent.prev().find('.textToShow').hide(0);
                clickedParent.prev().removeClass('textAnimated').css({ height: 'auto' });

            }, 320);
            $(this).removeClass('active');
            clickedParent.find('.buttonOpen').addClass('active');
        });

        if ($('#priceOpen').length) {
			
			var timer = null;

            $('#priceOpen').click(function() {
                if ($(this).hasClass('priceListOpened')) {
                    $(this).removeClass('priceListOpened');
                    $('#priceList').css({ height: 0 });
					clearTimeout(timer);
					timer = null;
                } else {
                    var subListHeight = 0;
                    var subListItems = $('#priceList ul').children();
                    subListItems.each(function() {
                        subListHeight += $(this).height();
                    });
                    $(this).addClass('priceListOpened');
                    $('#priceList').css({ height: subListHeight });
					timer = setTimeout(function(){$('#priceList').css({ height: 'auto' })}, 310);
                }
            });


            var pageWidth = $(window).width();
            if (pageWidth < 992) {
                $('#priceOpen').removeClass('priceListOpened');
                $('#priceList').css({ height: 0 });
				clearTimeout(timer);
				timer = null;
            }

            /*$(window).resize(function() {
                pageWidth = $(window).width();
                if (pageWidth < 992) {
                    $('#priceOpen').removeClass('priceListOpened');
                    $('#priceList').css({ height: 0 });
                }
            });*/
        }
		
		//if($('#pageID-16 #pageInfoBox2colContent').length){
		//	$('.textPopUp').mCustomScrollbar();
		//}
		
        if ($('#filteredClinics').length) {
            var allClinics = $('.clinicCard');
            var filterOptions = [];

            $('#filterOpen').click(function() {
                if ($(this).hasClass('filterListeOpened')) {
                    $(this).removeClass('filterListeOpened');
                    $('#filterList').css({ height: 0 });
                } else {
                    var subListHeight = 0;
                    var subListItems = $('#filterList ul').children();
                    subListItems.each(function() {
                        subListHeight += $(this).height();
                    });
                    $(this).addClass('filterListeOpened');
                    $('#filterList').css({ height: subListHeight + 60});
                }
            });

            $('#filterBox li a').click(function(ev) {
                ev.preventDefault();
				if(!$(this).parent().hasClass('rex-notclicable')){
					var subList = $(this).next('ul');
					if (subList.length) {
						var parentItem = $(this).parent();
						if (parentItem.hasClass('opened')) {
							parentItem.removeClass('opened');
							subList.css({ height: 0 });
						} else {
							var subListHeight = 0;
							var subListItems = subList.children();
							subListItems.each(function() {
								subListHeight += $(this).height();
							});
							parentItem.addClass('opened');
							subList.css({ height: subListHeight });
						}
					} else {
						//var filterItem = $(this).text();
						var filterItem = $(this).attr('data-filter');
						var clickedItem = $(this).parent();
						if (clickedItem.hasClass('marked')) {
							clickedItem.removeClass('marked');
							var itemPos = filterOptions.indexOf(filterItem);
							filterOptions.splice(itemPos, 1);
						} else {
							clickedItem.addClass('marked');
							filterOptions.push(filterItem);
						}
						if (filterOptions.length > 0) {
							$('.resetFilter').addClass('showReset');
						} else {
							$('.resetFilter').removeClass('showReset');
						}
						$('.clinicCard').hide(0).removeClass('clinicAnimated');
						allClinics.each(function() {
							var showClinic = true;
							var allTreatments = $(this).attr('data-filter');
							for (var i = 0; i < filterOptions.length; i++) {
								if (allTreatments.indexOf(filterOptions[i]) < 0) {
									showClinic = false;
									break;
								}
							}
							if (showClinic) {
								//$(this).addClass('clinicFiltered');
								$(this).show(0).addClass('clinicAnimated');
							}
						});
					}
					$('#moreClinics').hide();
				}

            });

            $('.resetFilter').click(function(ev) {
                ev.preventDefault();
                filterOptions = [];
                $('.resetFilter').removeClass('showReset');
                $('#filterBox li').removeClass('marked');
                $(this).addClass('clinicFiltered');
				$('.clinicCard').show(0).addClass('clinicAnimated');
            });
			
			

			/*
            var pageWidth = $(window).width();
            if (pageWidth < 992) {
                $('#filterOpen').removeClass('filterListeOpened');
                $('#filterList').css({ height: 0 });
            }

            $(window).resize(function() {
                pageWidth = $(window).width();
                if (pageWidth < 992) {
                    $('#filterOpen').removeClass('filterListeOpened');
                    $('#filterList').css({ height: 0 });
                    $('.resetFilter').removeClass('visible');
                }
            });
			*/
			var loadedClinicPos = 4;
			$('.moreClinicsButton').click(function(ev){
				ev.preventDefault();
				var nextClinic;
				for(var i=0;i<4;i++){
					nextClinic = $('.clinicCard').eq(loadedClinicPos);
					if(nextClinic.length){
						nextClinic.addClass('clinicFiltered');
						loadedClinicPos++;
					}else{
						$('#moreClinics').hide();
						break;
					}
				}
				nextClinic = $('.clinicCard').eq(loadedClinicPos);
				if(!nextClinic.length){$('#moreClinics').hide();}
			});

            $('.serviceItem').mouseenter(function() {
                $(this).find('.serviceInfo').show(0).addClass('serviceAnimated').addClass('showInfo');
            });

            $('.serviceItem').mouseleave(function() {
                $(this).find('.serviceInfo').hide(0).removeClass('serviceAnimated').removeClass('showInfo');
            });
        }
	
		//forms
        $('a[href="#easyForm"], .easyFormButton, .mailNavIcon').click(function(ev) {
            ev.preventDefault();
            if ($('#pageEasyFormBackground').hasClass('opened')) {
				$('body').removeClass('formOpened');
                $('#pageEasyFormBackground').removeClass('opened');
                $('#pageEasyFormBackground, #pageEasyForm').hide();
            } else {
                $('#callback').addClass('opened');
				var topPos = '50%';
				if($(window).width()<768){
					topPos = $(window).scrollTop()+10;
				}
				$('#pageEasyForm').css({top: topPos});
                $('#pageEasyFormBackground, #pageEasyForm').show();
				$('body').addClass('formOpened');
            }
        });

        $('#pageEasyFormBackground').click(function() {
			$('body').removeClass('formOpened');
            $('#pageEasyFormBackground').removeClass('opened');
            $('#pageEasyFormBackground, #pageEasyForm').hide();
        });

        $('#pageEasyFormClose').click(function(ev) {
            ev.preventDefault();
			$('body').removeClass('formOpened');
            $('#pageEasyFormBackground').removeClass('opened');
            $('#pageEasyFormBackground, #pageEasyForm').hide();
        });

        $('a[href="#callBack"]').click(function(ev) {
            ev.preventDefault();
            $('#callback').removeClass('opened');
            $('#callback').toggleClass('openedBottom');
        });
		
		$('.linkToForm').click(function(ev){
			ev.preventDefault();
			var formPos = $('#contactFormFooter').offset().top;
			$("html, body").animate({ scrollTop: formPos }, 500);
		});
		
		$('.mailNavIcon').click(function(ev) {
            ev.preventDefault();
            $('#header').removeClass('menu-open');
			$('html').removeClass('naviOpened');
            $('#pageContactForm').show();
        });
		
		/*
        $('a[href="#easyForm"]').click(function(ev) {
            ev.preventDefault();
            $('#pageContactForm').show();
        });
		*/
        $('.formContactInfoShowNumber').click(function(ev) {
            $(this).parent().toggleClass('moreNumbers');
        });

        $('#closeForm').click(function(ev) {
            ev.preventDefault();
            $('#pageContactForm').hide();
        });
		
        if ($("#formCallBack").length) {
            var formCallBack = jQuery("#formCallBack").validate({
                rules: {
                    formCallBackNummer: {
                        required: true
                    },
                },
                submitHandler: function(form) {
                    jQuery(form).ajaxSubmit({ target: "#callBackInfo" });
                    formCallBack.resetForm();
                    setTimeout(function() {
                        $('#callBackInfo').empty();
                    }, 5000);
                }
            });
        }
        if ($("#formCallBackExt").length) {
            var formCallBackExt = jQuery("#formCallBackExt").validate({
                rules: {
                    formCallBackExtNummer: {
                        required: true
                    },
                    formCallBackExtName: {
                        required: true
                    },
                },
                submitHandler: function(form) {
                    jQuery(form).ajaxSubmit({ target: "#callBackExtInfo" });
                    formCallBackExt.resetForm();
                    setTimeout(function() {
                        $('#callBackExtInfo').empty();
                    }, 5000);
                }
            });
        }
        if ($('#formEasy').length) {
            var formEasy = jQuery("#formEasy").validate({
                rules: {
                    formEasyName: {
                        required: true
                    },
                    formEasyPhone: {
                        required: true
                    },
                    formEasyMessage: {
                        required: true
                    },
                    formEasyMail: {
                        required: true,
                        email: true
                    }
                },
                submitHandler: function(form) {
                    jQuery(form).ajaxSubmit({ target: "#formEasyInfo" });
                    formEasy.resetForm();
                    setTimeout(function() {
                        $('#formEasyInfo').empty();
                    }, 5000);
                }
            });
        }
        if ($("#formEasy2").length) {
            var formEasy2 = jQuery("#formEasy2").validate({
                rules: {
                    formEasyName2: {
                        required: true
                    },
                    formEasyPhone2: {
                        required: true
                    },
                    formEasyMessage2: {
                        required: true
                    },
                    formEasyMail2: {
                        required: true,
                        email: true
                    }
                },
                submitHandler: function(form) {
                    jQuery(form).ajaxSubmit({ target: "#formEasyInfo2" });
                    formEasy2.resetForm();
					gtag('event', 'form#1');
                    setTimeout(function() {
                        $('#formEasyInfo2').empty();
                    }, 5000);
                }
            });
        }

        if ($("#formContact").length) {
			
			var dictDefaultMessage = 'Klick das Feld oder ziehe die Dateien hier hin, um sie hochzuladen';
			var dictFallbackMessage = 'Ihr Browser unterstützt keine "Drag & Drop" Funktionalität.';
			var dictFileTooBig = 'Datei ist zu groß. Maximale erlaubte Größe ist 2 MB.';
			var ictInvalidFileType = 'Dieses Dateienformat ist nicht erlaubt';
			var dictRemoveFile = 'Datei löschen';
			var dictMaxFilesExceeded = 'Sie können nur bis 3 Dateien hochladen';
			if ($('html').attr('lang') == 'ru') {
				var dictDefaultMessage = 'Выберите файлы или просто перетащите сюда, чтобы загрузить их';
			}
			
			var fileFormUpload = new Dropzone( '#file-upload', {
			  url: 'http://ms-ger.ru/resources/fn/uploadFile.php',
			  paramName: "fileUp",
			  maxFilesize: 2,
			  uploadMultiple: true,
			  maxFiles: 3,
			  addRemoveLinks: true,
			  acceptedFiles: 'image/*,application/pdf,application/doc,application/docx',
			  dictDefaultMessage: dictDefaultMessage,
			  init: function() {
				this.on("success", function(file, responseText) {
				  var currentFileList = $('#formFileList').val();
				  $('#formFileList').val(currentFileList+responseText);
				});
			  }
			});
			
			$.validator.addMethod("checkRadio1", function(value, element) {
				var inputCheck = false;
				var inputValue = $('#formContactVisa').val();
				if(inputValue.length>0){
					inputCheck = true;
				}else{
					$('.formContactVisa .checkbox').addClass('errorRadio');
				}
				return inputCheck
			}, "Check radiobuttons");
			
			$.validator.addMethod("checkRadio2", function(value, element) {
				var inputCheck = false;
				var inputValue = $('#formContactHotel').val();
				if(inputValue.length>0){
					inputCheck = true;
				}else{
					$('.formContactHotel .checkbox').addClass('errorRadio');
				}
				return inputCheck
			}, "Check radiobuttons");
			
			$.validator.addMethod("checkBox", function(value, element) {
				var inputCheck = false;
				var inputValue = $('#formContactPurpose1').val()+$('#formContactPurpose2').val()+$('#formContactPurpose3').val()+$('#formContactPurpose4').val();
				if(inputValue.length>0){
					inputCheck = true;
				}else{
					$('.checkListe .checkbox').addClass('errorBox');
				}
				return inputCheck
			}, "Check radiobuttons");
			
            var formContact = jQuery("#formContact").validate({
                rules: {
                    formContactName: {
                        required: true
                    },
					formContactDay: {
                        required: true
                    },
					formContactMonth: {
                        required: true
                    },
					formContactYear: {
                        required: true
                    },
                    formContactPhone: {
                        required: true
                    },
					formContactData: {
                        required: true
                    },
					formContactDiagnose: {
                        required: true
                    },
					formContactComorbidities: {
                        required: true
                    },
					formContactStay: {
                        required: true
                    },
                    formContactCity: {
                        required: true
                    },
                    formContactCountry: {
                        required: true
                    },
                    formContactMail: {
                        required: true,
                        email: true
                    },
					formContactVisa: {
						checkRadio1: true
					},
					formContactHotel: {
						checkRadio2: true
					},
					formContactPurpose1: {
						checkBox: true
					}
                },
                submitHandler: function(form) {
                    jQuery(form).ajaxSubmit({ target: "#formContactInfo" });
                    formContact.resetForm();
                    $('span.clicked').removeClass('clicked');
                    $('input').not('#formContactLang, #formEasyLang, #formEasyLang2, #formCallBackLang, #formCallBackExtLang').val('');
					fileFormUpload.removeAllFiles(true);
                    setTimeout(function() {
                        $('#formContactInfo').empty();
                    }, 5000);
                }
            });
        }

        $('.checkItemSingle').click(function() {
            var clickedItem = $(this);
            var inputCheck = clickedItem.next('input');
            if (clickedItem.hasClass('clicked')) {
                clickedItem.removeClass('clicked');
                inputCheck.val('');
            } else {
                clickedItem.addClass('clicked');
                var spanVal = clickedItem.attr('data-info');
                inputCheck.val(spanVal);
            }
        });
		
		/*
        $('.toggleBox').click(function() {
            var boxForToggle = $(this).attr('data-box');
            if ($(this).hasClass('toggled')) {
                $(this).removeClass('toggled');
                $('.' + boxForToggle).addClass('boxDisabled');
                $('.' + boxForToggle + ' .clicked').removeClass('clicked');
                $('.' + boxForToggle + ' input').val('');
            } else {
                $(this).addClass('toggled');
                $('.' + boxForToggle).removeClass('boxDisabled');
            }
        });
		*/
		$('.checkListe .checkbox').click(function(){
			$('.checkListe .checkbox').removeClass('errorBox');
		});

        $('#formContact .checkbox').click(function() {
			$(this).removeClass('errorRadio').parent().siblings().find('.checkbox').removeClass('errorRadio');
            var clickedItem = $(this);
            var spanCheck = clickedItem.find('span');
            if (spanCheck.hasClass('checkItem')) {
                var inputCheck = clickedItem.find('input');
                if (spanCheck.hasClass('clicked')) {
                    spanCheck.removeClass('clicked');
                    inputCheck.val('');
                } else {
                    spanCheck.addClass('clicked');
                    var spanVal = spanCheck.attr('data-info');
                    inputCheck.val(spanVal);
                }
            } else {
                if (!spanCheck.hasClass('clicked')) {
                    var inputName = spanCheck.attr('data-item');
                    var spanVal = spanCheck.attr('data-info');
                    $('span[data-item="' + inputName + '"]').removeClass('clicked');
                    spanCheck.addClass('clicked');
                    $('input[name="' + inputName + '"]').val(spanVal);
                }
            }

        });
        if ($('.sidebar .widget').length) {
			var timer = null;
			
            $('.widget li span').click(function(ev) {
				ev.stopImmediatePropagation();
				ev.stopPropagation();
                var clickedItem = $(this).parent();
                var subHeight = 0;
                var itemUl = clickedItem.parent().find('ul');
                if (itemUl.length > 0) {
                    if (clickedItem.hasClass('opened')) {
                        clickedItem.removeClass('opened');
                        itemUl.addClass('animated').removeClass('opened').css({ height: 0 });
						clearTimeout(timer);
						timer = null;
                    } else {
                        var allItems = clickedItem.parent().find('.rex-navi2 li');
                        allItems.each(function() {
                            subHeight += $(this).height();
                        });
                        if (itemUl.height() != 0) {
                            itemUl.removeClass('animated').css({ height: subHeight });
                            clickedItem.removeClass('opened');
                            itemUl.addClass('animated').removeClass('opened').css({ height: 0 });
							clearTimeout(timer);
							timer = null;
                        } else {
                            clickedItem.addClass('opened');
                            itemUl.addClass('animated').addClass('opened').css({ height: subHeight });
							timer = setTimeout(function(){itemUl.css({ height: 'auto' })}, 310);
                        }
                    }
                }
				return false;
            });

            /*$(window).resize(function() {

                pageWidth = $(window).width();
                if (pageWidth < 992) {
                    $('.widget li span').removeClass('opened');
                    $('.rex-navi2').removeClass('opened').css({ height: 0 });
                } else {
                    $('.rex-navi2.opened').each(function() {
                        if ($(this).height() != 0) {
                            $(this).removeClass('animated').css({ height: 'auto' });
                        }
                    });
                }
            });*/
        }
		
		var counted = true;
		
		$(".priceWithInfo").waypoint(function() {
			$('.price').each(function () {
				$(this).prop('Counter',0).animate({
					Counter: $(this).text()
				}, {
					duration: 5000,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.ceil(now));
					}
				});
			});
			counted = true;
		}, { offset: '80%' });
		
        $(".img6box").waypoint(function() {
            $(this).addClass('popped');
        }, { offset: '80%' });
		
		$(".numInfo").waypoint(function() {
            $(this).addClass('activated');
        }, { offset: '80%' });
		
		$('.iconItem').waypoint(function() {
            $(this).parent().addClass('svgAnimated');
        }, { offset: '80%' });
		
        $(".panel.panel-default.paper").waypoint(function() {
            $('.panel.panel-default.paper').addClass('popped');
        }, { offset: '80%' });
		
		$('#scrollToTop').click(function(ev){
			ev.preventDefault();
			var toScroll = $(window).scrollTop();
			var scrollSpeed = toScroll * 0.3;
			$("html, body").animate({ scrollTop: 0 }, scrollSpeed);
		});
		
		
		$('#header .menu li.dropdown').mouseenter(function(){
			var navItem = $(this).find('ul').show(0);
			var allItems = navItem.find('li');
			var navHeight = 20;
			allItems.each(function(){
				navHeight += $(this).height();
			});
			navItem.css({height: navHeight});
		});
		$('#header .menu li.dropdown').mouseleave(function(){
			var navItem = $(this).find('ul');
			navItem.css({height: 0}).delay(250).hide(0);
		});
		
		$('#mobileNavBox a').click(function(ev){
			ev.stopPropagation();
		})
		$('#mobileNavBox li span').click(function(ev){
			ev.preventDefault();
			ev.stopImmediatePropagation();
			ev.stopPropagation();
			$(this).parent().parent().find('ul').addClass('mobileSubNavOpened');
			$('#mobileNavBox').addClass('mobileSubNavOpened');
		});
		$('#navBack').click(function(ev){
			ev.preventDefault();
			$('.mobileSubNavOpened').removeClass('mobileSubNavOpened');
		});
    });

    /* Window Load/All Media Loaded */
    jQuery(window).load(function() {

        site_loader($(this));
        fullscreen_section($(this));
        parallax_image();

        $('.section').each(function() {
            animate_start($(this));
        });
		
		var pageHeight = $('body').height();
		var windowHeight = $(window).height();
		var windowWidth = $(window).width();
		
		$(window).resize(function(){
			pageHeight = $('body').height();
			windowHeight = $(window).height();
			windowWidth = $(window).width();
		});
		
		$(window).scroll(function(){
			if(windowWidth > 1092){
				if($(window).scrollTop() > pageHeight - windowHeight - 65){
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').addClass('onFooter');
				}else{
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').removeClass('onFooter'); 
				}
			}else if(windowWidth > 409 && windowWidth < 1093){
				if($(window).scrollTop() > pageHeight - windowHeight - 104){
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').addClass('onFooter');
				}else{
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').removeClass('onFooter'); 
				}
			}else{
				if($(window).scrollTop() > pageHeight - windowHeight - 125){
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').addClass('onFooter');
				}else{
					$('#scrollToTop, #mobilePhoneIcon, #mobilePhoneBox, #menu, .grecaptcha-badge').removeClass('onFooter'); 
				}
			}
		});

    });

    //
    //  FUNCTIONS
    //



    /*
    // Flex slider
    var flex_slider = function($this){
        $('.hero-slider').flexslider({
            animation: "fade",
            direction: "horizontal",
            animationSpeed: 1000,
            animationLoop: true,
            smoothHeight: true,
            directionNav: false,
            controlsContainer: ".hero-controls",
            controlNav: true,
            slideshow: true,               
            useCSS: true,

            // for Transparent Header
            after: function(slider){
                if($('li.flex-active-slide').hasClass("dark-slider")){
                    $('.header').addClass('white-header');
                }
                else {       
                    $('.header').removeClass('white-header');
                }
                if($('li.flex-active-slide').hasClass("white-slider")){
                    $('.header').addClass('black-header');
                }
                else {       
                    $('.header').removeClass('black-header');
                }
            },
            start: function(slider){
                if($('li.flex-active-slide').hasClass("dark-slider")){
                    $('.header').addClass('inverse-header');
                }
                else {       
                    $('.header').removeClass('inverse-header');
                }
                if($('li.flex-active-slide').hasClass("white-slider")){
                    $('.header').addClass('black-header');
                }
                else {       
                    $('.header').removeClass('black-header');
                }
            }
        });
        $('.flexslider').flexslider({
            animation: "fade",
            controlNav: true,
            useCSS: true,
            directionNav: false
        });
    }
	*/
    // Animateion with Waypoints.js
    var animate_start = function($this) {
        $this.find('.animate').each(function(i) {
            var $item = jQuery(this);
            var animation = $item.data("animate");

            $item.waypoint(function(direction) {
                $item.css({
                    '-webkit-animation-delay': (i * 0.1) + "s",
                    '-moz-animation-delay': (i * 0.1) + "s",
                    '-ms-animation-delay': (i * 0.1) + "s",
                    '-o-animation-delay': (i * 0.1) + "s",
                    'animation-delay': (i * 0.1) + "s"
                });
                $item.removeClass('animate').addClass('animated ' + animation).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                    jQuery(this).removeClass(animation + ' animated');
                });
            }, {
                offset: '80%',
                triggerOnce: true
            });
        });
    };

    //  Parallax Background (Stellar.js)
    var parallax_image = function($this) {
        $.stellar({
            horizontalScrolling: false,
            responsive: true
        });
    }

    // Youtube Video Background
    var youtube_bg = function($this) {
        $('.video-background').each(function() {
            $(this).YTPlayer({
                videoURL: $(this).data('video-id'),
                containment: 'self',
                showControls: false,
                autoPlay: true,
                loop: true,
                mute: true,
                startAt: 0,
                opacity: 1,
                addRaster: true,
                quality: 'default'
            });
        });
    }

    // Fullscreen ection
    var fullscreen_section = function($this) {
        $this.find('.fullscreen').each(function() {
            var $this = $(this);
            var resize_height = function() {
                $this.height($(window).height());
                fullscreenFix();
            }
            resize_height();
            $(window).resize(function() {
                resize_height();
            });
        });
    }

    function fullscreenFix() {
        var h = $(window).height();
        // set .fullscreen height
        $(".fullscreen").children(".container").each(function(i) {
            var hc = $(this).innerHeight() + 270;
            if (hc >= h) {
                $(this).parent(".fullscreen").addClass("not-overflow");
            } else {
                $(this).parent(".fullscreen").removeClass("not-overflow");
            }
        });
    }

    // AUTO HEIGHT
    var fix_height = function($this) {
        var auto_height = function() {
            if ($(window).width() > 991) {
                $('.auto-height').each(function() {
                    var element = $(this);
                    var height = element.height();
                    var parent_height = element.parent().parent().height();
                    element.css('height', parent_height);
                });
            } else {
                $('.auto-height').each(function() {
                    var element = $(this);
                    element.css('height', 'auto');
                });
            }
        }
        auto_height();
        $(window).resize(function() {
            auto_height();
        });
    }
/*
    // PROGRESS BAR
    var progress_bar = function($this) {
        $this.find('.progress-bar').each(function() {
            var $this = $(this);

            $this.waypoint(function(direction) {
                $this.css('width', $this.attr('aria-valuenow') + '%');
            }, {
                offset: '80%',
                triggerOnce: true
            });
        });
    }
*/
    // MOBILE NAV
    var mobile_nav = function($this) {
        $('.menu-toggle').on('click', function() {
            $(this).closest('header').toggleClass('menu-open');
			$('html').toggleClass('naviOpened');
            if ($(this).closest('header').hasClass('menu-3')) {
                $(this).toggleClass('active');
            }
			$('.mobileSubNavOpened').removeClass('mobileSubNavOpened');
        });
        /*
		var add_mm_class = function() {
            if ($('html').attr('lang') == 'ru') {
                if ($(window).width() < 1200) {
                    $('.menu').addClass('mobile-menu')
                } else {
                    $('.menu').removeClass('mobile-menu')
                }
            } else {
                if ($(window).width() < 992) {
                    $('.menu').addClass('mobile-menu')
                } else {
                    $('.menu').removeClass('mobile-menu')
                }
            }

        }
        add_mm_class();
        $(window).resize(function() {
            add_mm_class();
        });*/
    }
/*
    // OWL CAROUSEL
    var owl_carousel = function($this) {
        $('.owl-carousel').each(function() {
            var $this = $(this);
            $this.owlCarousel({
                loop: true,
                margin: 0,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true
                    },
                    768: {
                        items: $this.data('col-sm'),
                        nav: false
                    },
                    992: {
                        items: $this.data('col-md'),
                        nav: true,
                        loop: false
                    },
                    1200: {
                        items: $this.data('col-lg'),
                        nav: true,
                        loop: false
                    }
                }
            });
        });
    }
*/
    // ONE PAGE SCROLLING
	
    var one_page_scroll = function($this) {
        $(function() {
            var sections = jQuery('.section');
            var navigation_links = jQuery('.menu a, a.scroll-down');
            sections.waypoint({
                handler: function(direction) {
                    var active_section;
                    active_section = jQuery(this);
                    if (direction === "up") active_section = active_section.prev();
                    var active_link = jQuery('.menu a[href="#' + active_section.attr("id") + '"]');
                    navigation_links.removeClass("active");
                    active_link.addClass("active");
                    active_section.addClass("active-section");
                },
                offset: '80%'
            });
        });

        // SCROLL DOWN
        $('.menu, .scroll-down').each(function() {
            var $this = $(this);
            $this.localScroll({
                offset: -60,
                duration: 500
            })
        });
        $('a.scroll-down').localScroll();
    }
	
    // REMOVE PAGE LOADER SCREEN
    var site_loader = function($this) {
            $('.loader').css('opacity', 0);
            setTimeout(function() {
                $('.loader').hide();
                $('body').addClass('loaded');
				$(".animatedItem").waypoint(function() {
					$(this).addClass('poppedItem');
				}, { offset: '100%' });
            }, 600);
        }

        // STICKY HEADER
    var sticky_header = function($this) {

        $(window).scroll(function() {
            if (window.scrollY > 100 && !$('.mobile-toggle').is(":visible")) {
                $('#header').addClass('sticky');
				$('body').addClass('headerSticky');
            } else {
                $('#header').removeClass('sticky');
				$('body').removeClass('headerSticky');
            }
        });
    }
})(jQuery);